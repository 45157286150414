<template>
  <v-form @submit.prevent="onSubmit" ref="form">
    <v-select
      label="Профиль"
      :items="profiles"
      v-model="formData.profiles"
      item-text="name"
      item-value="id"
      multiple
    ></v-select>
    <v-text-field
      label="Название заболевания (состояния)"
      v-model="formData.name"
      :rules="[rules.required]"
    ></v-text-field>
    <v-text-field
      label="МКБ10"
      v-model="formData.mkb10_codes"
      :rules="[rules.required]"
    ></v-text-field>
    <v-text-field
      class="mb-8"
      label="Ссылка"
      v-model="formData.cr_link"
    ></v-text-field>
    <FormButtons />
  </v-form>
</template>

<script>
import FormButtons from '@/components/buttons/FormButtons.vue'

import { mapActions, mapGetters } from 'vuex'

import { routeParams } from '@/mixins/routeParams'
import { sendFormWithToastAndRedirect } from '@/mixins/sendFormWithToastAndRedirect'
import { formMixin } from '@/mixins/formMixin'

import { addNosologia, editNosologia } from '@/lib/requestsAPI'

import { ADMIN, NOSOLOGIES, NOSOLOGIA_SHORT } from '@/store/const/path'

export default {
  mixins: [formMixin, routeParams, sendFormWithToastAndRedirect],
  data() {
    return {
      formData: {},
      TEMPLATE_FORM_DATA: {
        name: '',
        profiles: [],
        mkb10_codes: '',
        cr_link: '',
      },
      rules: {
        required: v => !!v || 'Обязательное поле',
      },
    }
  },
  components: {
    FormButtons,
  },
  computed: {
    ...mapGetters({
      profiles: 'getProfilesNew',
    }),
    successPath() {
      return `/${ADMIN}/${NOSOLOGIES}/${NOSOLOGIA_SHORT}-`
    },
  },
  methods: {
    ...mapActions(['loadProfiles']),
    prepareDefaultValues() {
      this.loadProfiles()
    },
    prepareSendData() {
      const result = { ...this.formData }
      if (result.cr_link === '') result.cr_link = null
      if (result.profiles.length === 0) return result
      if (typeof result.profiles[0] === 'number') return result
      return {
        ...result,
        profiles: result.profiles.map(p => p.id),
      }
    },
    add() {
      this.sendFormWithToastAndRedirect({
        action: addNosologia,
        data: this.formData,
        successPath: this.successPath,
        updateSuccessPath: true,
        successMessage: `Успешно создали заболевание (состояние) «${this.formData.name}»`,
        errorMessage: 'Ошибка при создании заболевания (состояния)',
      })
    },
    edit() {
      this.sendFormWithToastAndRedirect({
        action: editNosologia,
        data: { id: this.nosologiaId, data: this.prepareSendData() },
        successPath: `${this.successPath}${this.nosologiaId}`,
        updateSuccessPath: false,
        successMessage: 'Заболевание (состояние) успешно отредактировано',
        errorMessage: 'Ошибка при редактировании заболевания (состояния)',
      })
    },
  },
}
</script>
