<template>
  <DisplayLoading
    :isLoading="isLoading"
    :isError="isError"
    :loadingMessage="loadingMessage"
    :errorMessage="errorMessage"
  >
    <div v-if="pageData">
      <PageHeader text="Редактировать" />
      <NosologiaForm v-if="nosologia.id" :initialValues="nosologia" />
    </div>
  </DisplayLoading>
</template>

<script>
import DisplayLoading from '@/components/common/DisplayLoading.vue'
import NosologiaForm from '@/components/forms/NosologiaForm.vue'
import PageHeader from '@/components/common/PageHeader.vue'

import { routeParams } from '@/mixins/routeParams'
import { loadPageData } from '@/mixins/loadPageData'

import { API_NOSOLOGIES } from '@/store/const/apiPaths'

export default {
  data() {
    return {
      loadingMessage: 'Загружаю заболевание (состояние)',
    }
  },
  components: {
    PageHeader,
    NosologiaForm,
    DisplayLoading,
  },
  mixins: [routeParams, loadPageData],
  computed: {
    loadUrl() {
      return `${API_NOSOLOGIES}/${this.nosologiaId}`
    },
    errorMessage() {
      return `Не удалось загрузить данные для заболевания (состояния): ${this.nosologiaId}`
    },
    nosologia() {
      return this.pageData
    },
  },
}
</script>
